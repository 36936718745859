import {
  Title,
  Text,
  Space,
} from '@mantine/core';

import {
  getPatientLabelFirstLine,
  getPatientLabelSecondLine,
  translatedPatientAgeAndSex,
} from '../utils/patient.utils';
import { STYLE } from '../types/constants';
import BoxOrderView from './BoxOrderView';

const OrderViewPatientComponent = ({ order }) => {
  return (
    <BoxOrderView span={5} minHeight={28}>
      <Title
        order={5}
        style={{
          color: STYLE.primary,
        }}
      >
        Order {order.orderReference}
      </Title>
      <Space h="md" />
      <Title order={5}>Patient</Title>
      <Text>
        {getPatientLabelFirstLine(order)}
      </Text>
      <Text>
        {translatedPatientAgeAndSex(order?.patient)}
      </Text>
      <Space h="md" />
      <Title order={5}>Doctor</Title>
      <Text>
        {order?.user?.firstName ?? ""} {order?.user?.lastName ?? ""}
      </Text>
    </BoxOrderView>
  );
};

export default OrderViewPatientComponent;
