import { gql } from '@apollo/client';

export const GET_ALL_ITEM_MATERIALS = gql`
  query GetAllItemMaterials {
    getAllItemMaterials {
      id
      label
    }
  }
`;
