import React from "react";
import {
  useQuery,
} from "@apollo/client";
import {
  useNavigate,
} from "react-router-dom";

import {
  GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER,
} from "../../gql/";
import { Button, Table } from "@mantine/core";

const ChatList = () => {

  const navigate = useNavigate();

  const { data } = useQuery(GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER);
  const markAsViewed = (chatId : string) => {
    // TODO : add code here
  }
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Order references</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Last unread messages</Table.Th>
          <Table.Th>Actions</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {data && data.getLastUnreadOfEachOrder.map((chat) => {
          return (
            <>
              <Table.Tr key={chat.order.id}>
                <Table.Td>{chat.order.orderReference}</Table.Td>
                <Table.Td>{chat.order.status}</Table.Td>
                <Table.Td>{chat.message}</Table.Td>
                <Table.Td style={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  gap: '5px'
                }}>
                  <Button onClick={() => navigate(`/orders/${chat.order.id}`)}>
                    View order
                  </Button>
                  <Button onClick={() => markAsViewed(chat.id)}>
                    Mark as viewed
                  </Button>
                </Table.Td>
              </Table.Tr>
            </>
          )
        })}
      </Table.Tbody>
    </Table>
  );
};

export default ChatList;
