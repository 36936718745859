import { Sex } from '../types/enums';

export const getPatientLabelFirstLine = (newOrder: any): string => {
  if (newOrder.patient) {
    return `${newOrder.patient.firstName} ${newOrder.patient.lastName}`;
  }
  return "non renseigné";
};

export const getPatientLabelSecondLine = (newOrder: any): string => {
  if (newOrder?.patient) {
    return `${getAge(newOrder?.patient?.birthdate as string) + " ans"}, 
    ${getPatientSexLabel(newOrder?.patient?.sex as Sex)}`;
  }
  return "";
};

export function getAge(dateString: string): number {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const translatedPatientSexLabel = (sex?: Sex): string => {
  switch (sex) {
    case Sex.FEMALE:
      return "Woman";
    case Sex.MALE:
      return "Man";
  }
  return "Sex not specified";
};

export const getPatientSexLabel = (sex: Sex | null | undefined): string => {
  switch (sex) {
    case Sex.FEMALE:
      return "Femme";
    case Sex.MALE:
      return "Homme";
  }
  return "Sexe non renseigné";
};

export const translatedPatientAgeAndSex = (patient: { birthdate: number, sex: Sex }): string => {
  if (!patient?.birthdate){
    return ("");
  }
  const { birthdate, sex } = patient;
  return `${getAge(birthdate as string) + " years old"}, ${translatedPatientSexLabel(sex as Sex)}`;
};

