import { Space, Button } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { notifications } from '@mantine/notifications';

import {
  TextInputRef,
  SelectInputRef,
  ItemType,
  WorkflowType,
  STYLE,
} from '@jasper/shared';

import {
  GET_ALL_SHADES,
  GET_ALL_MATERIALS,
  CREATE_REMOVABLE_ITEM,
  UPDATE_REMOVABLE_ITEM,
} from '../../../gql/';

const CreateRemovableItemComponent = ({ closeModal, productId, refetch, item }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: {
      itemType: item?.itemType,
      teeth: item?.teeth.join(','),
      teethToManufacture: item?.teethToManufacture.join(','),
      material: item?.itemMaterial?.id,
      shade: item?.teethShade?.id,
      gingivaShade: item?.gingivaShade?.id,
      workflowType: item?.workflowType,
    }
  });

  const { data: allMaterials } = useQuery(GET_ALL_MATERIALS);
  const { data: allShades } = useQuery(GET_ALL_SHADES);
  const [createRemovableItem] = useMutation(CREATE_REMOVABLE_ITEM);
  const [updateRemovableItem] = useMutation(UPDATE_REMOVABLE_ITEM);
  
  const getAllShades = () => {
    return (allShades?.getAllItemShades ?? []).map((shade) => ({value: shade.id, label: shade.label}));
  };

  const getAllMaterials = () => {
    return (allMaterials?.getAllItemMaterials ?? []).map((material) => ({value: material.id, label: material.label}));
  };

  const submit = async(data) => {
    try {
      if (item?.id){
        await updateRemovableItem({
          variables: {
            data: {
              itemMaterial: {
                connect: {
                  id: data.material,
                }
              },
              teethShade: {
                connect: {
                  id: data.shade,
                }
              },
              gingivaShade: {
                connect: {
                  id: data.gingivaShade,
                }
              },
              itemType: {
                set: data.itemType,
              },
              teeth: data.teeth.split(',').map((t) => parseInt(t)),
              teethToManufacture: {
                set: data.teethToManufacture.split(',').map((t) => parseInt(t))
              },
              workflowType: {
                set: data.workflowType,
              }
            },
            where: {
              id: item.id
            }
          }
        });
        notifications.show({
          title: "Removable item updated",
          color: "green",
          message: "",
        });
      } else {
        await createRemovableItem({
          variables: {
            args: {
              itemType: data.itemType,
              gingivaShade: {
                connect: {
                  id: data.gingivaShade
                }
              },
              teeth: data.teeth.split(',').map((t) => parseInt(t)),
              teethShade: {
                connect: {
                  id: data.shade, 
                }
              },
              teethToManufacture: {
                set: data.teethToManufacture.split(',').map((t) => parseInt(t))
              },
              workflowType: data.workflowType,
              itemMaterial: {
                connect: {
                  id: data.material
                }
              },
              product: {
                connect: {
                  id: productId
                }
              }
            }
          }
        });
        notifications.show({
          title: "Removable item created",
          color: "green",
          message: "",
        });
      }
      refetch();
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to create or update removable item",
        color: "red",
        message: "",
      }); 
    }
    closeModal();
  };

  return (
    <>
      <SelectInputRef
        name="itemType"
        label="Item type"
        data={Object.keys(ItemType)}
        errors={errors}
        control={control}
      />
      <Space h="md"/>
      <TextInputRef
        name="teeth"
        label="Teeth"
        errors={errors}
        control={control}
      />
      <Space h="md"/>
      <TextInputRef
        name="teethToManufacture"
        label="Teeth To Manufacture"
        errors={errors}
        control={control}
      />
      <Space h="md"/>
      <SelectInputRef
        name="material"
        label="Material"
        data={getAllMaterials()}
        errors={errors}
        control={control}
      />
      <Space h="md"/>
      <SelectInputRef
        name="shade"
        label="Shade"
        data={getAllShades()}
        errors={errors}
        control={control}
      />
      <Space h="md"/>
      <SelectInputRef
        name="gingivaShade"
        label="Gingiva shade"
        data={getAllShades()}
        errors={errors}
        control={control}
      />
      <Space h="md"/>
      <SelectInputRef
        name="workflowType"
        label="Workflow Type"
        data={Object.keys(WorkflowType)}
        errors={errors}
        control={control}
      />
      <Space h="md"/>
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={handleSubmit(submit)}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default CreateRemovableItemComponent;
