import { ItemType, FileType } from '/@/types/enums';
import { Order } from '/@/types/interfaces';

export const isImplant = (order: Order): boolean => {
  return (order?.products ?? []).reduce((acc, product) => {
    if (
      product?.productType?.toUpperCase() === ItemType.IMPLANT
      || product?.productType?.toUpperCase() === ItemType.IMPLANT_CROWN
      || product?.productType?.toUpperCase() === ItemType.ABUTMENT
      || product?.productType?.toUpperCase() === ItemType.CUSTOM_ABUTMENT
      || product?.productType?.toUpperCase() === ItemType.SPLINTED_CROWN_IMPLANT
      || product?.productType?.toUpperCase() === ItemType.BRIDGE_IMPLANT
      || product?.productType?.toUpperCase() === ItemType.BRIDGE_PONTIC
      || (product?.implantItems ?? []).length > 0
    ){
      acc = true;
    }
    return (acc);
  }, false);
};

export const isOrderWithNoAttachement = (order: Order): boolean => {
  return (order?.files ?? []).reduce((acc, file) => (file?.fileType === FileType.ORDER_FILE), false);
};

