import {
  useState,
} from "react";
import {
  Space,
  Button,
  Text,
  Select,
} from "@mantine/core";

import {
  STYLE
} from "../../types/constants";

const SendOrderToSupplierModal = (
  props: {
    suppliers: { id: string, name: string }[],
    onSubmit: (selectedSupplierId: string) => void,
  }
) => {
  const [selectedSupplierId, setSelectedSupplierId] = useState<string>("");

  return (
    <>
      <Select
        label="Which supplier would you like to send the case to ?"
        placeholder="Please select a supplier"
        data={(props?.suppliers ?? []).map((supplier: { id: string, name: string }) => { return {value: supplier.id, label: supplier.name} })}
        onChange={(e) => setSelectedSupplierId(e)}
      />
      <Space h="md" />
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => props.onSubmit(selectedSupplierId)}
          style={{ backgroundColor: STYLE.primary }}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default SendOrderToSupplierModal;
