import { Stepper } from "@mantine/core";
import {
  IconAlertTriangle,
  IconBasket,
  IconCircleCheck,
  IconTools,
  IconTruckDelivery,
} from "@tabler/icons-react";
import React, {FC} from "react";

import { OrderStatus } from '../types/enums';
import { Order } from '../types/interfaces';
import { getOrderStatusStep } from "../utils/order.utils";
import { STYLE } from '../types/constants';

type OrderStatusStepperProps = {
  orderStatus: number;
  order: Order;
  labelsUnder?: boolean;
  grow?: boolean;
};

const OrderStatusStepper: FC<OrderStatusStepperProps> = ({
  orderStatus,
  order,
  labelsUnder = false,
  grow = false,
}) => {
  const orderDate = new Date(order?.createdAt);
  let expectedDeliveryDate = null;
  if (order?.deliveryDate !== null) {
    expectedDeliveryDate = new Date(0);
    expectedDeliveryDate.setUTCMilliseconds(order?.deliveryDate);
  } else {
    expectedDeliveryDate = new Date();
    expectedDeliveryDate.setDate(orderDate.getDate() + 10);
  }

  return (
    <Stepper
      active={orderStatus}
      color={STYLE.primary}
    >
      <Stepper.Step
        style={{ display: "flex", flexDirection: "column", textAlign: "center" }}
        label={orderStatus === 0 ? "Started" : "Ordered"}
        icon={<IconBasket/>}
        completedIcon={<IconBasket />}
      />
      <Stepper.Step
        style={{ display: "flex", flexDirection: "column", textAlign: "center" }}
        label="Production"
        icon={<IconTools />}
        completedIcon={<IconTools />}
      />
      <Stepper.Step
        style={{ display: "flex", flexDirection: "column", textAlign: "center" }}
        label="Shipping"
        completedIcon={<IconTruckDelivery />}
        icon={<IconTruckDelivery />}
      />
      <Stepper.Step
        style={{ display: "flex", flexDirection: "column", textAlign: "center" }}
        icon={<IconCircleCheck />}
        completedIcon={<IconCircleCheck />}
        label="Delivery"
      />
    </Stepper>
  );
};

export default OrderStatusStepper;
