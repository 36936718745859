export const STYLE = {
  primary: "#4362D0",
  border: "#ebf0f6",
};

export const YES_OR_NO_CHOICE = [
  {
    value: true,
    label: "Yes"
  },
  {
    value: false,
    label: "False"
  }
]

export const TOGGLE_FEATURE = {
  NEW_WORKFLOW: true,
  CHAT: false,
  QR_CODE : false,
};
