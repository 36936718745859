import { OrderStatus  } from '@jasper/shared';

export const getPreviousOrderStatus = (
    currentIndex: number,
    returnVal: string[],
    listAllOrderStyle: string[]
) => {
    if (currentIndex !== 0) {
        returnVal.push(listAllOrderStyle[currentIndex - 1]);
    }
    return returnVal
};

export const getNextOrderStatus = (
    currentIndex: number,
    returnVal: string[],
    listAllOrderStyle: string[]
) => {
    if (currentIndex !== (listAllOrderStyle.length - 1) && currentIndex !== 0) {
        returnVal.push(listAllOrderStyle[currentIndex + 1]);
    }
    return returnVal
};

export const getDeliveredOrderStatus = (
    currentIndex: number,
    returnVal: string[],
    listAllOrderStyle: string[]
) => {
    if (
        ![
            listAllOrderStyle.length - 2,
            listAllOrderStyle.length - 1
        ].includes(currentIndex)
    ) {
        returnVal.push(OrderStatus.DELIVERED);
    }
    return returnVal
};

export const getUpdateSelectOptions = (status: string) => {
    if (updatableStatusFilter(status)) {
        let returnVal: string[] = [];
        const listAllOrderStyle = Object.keys(OrderStatus);
        const currentIndex = listAllOrderStyle.findIndex((value) => value === status);
        returnVal = getPreviousOrderStatus(currentIndex, returnVal, listAllOrderStyle)
        returnVal = getNextOrderStatus(currentIndex, returnVal, listAllOrderStyle)
        returnVal = getDeliveredOrderStatus(currentIndex, returnVal, listAllOrderStyle)
        return returnVal;
    }
}
export const updatableStatusFilter = (status: string) =>
    ![
        'ALL',
        OrderStatus.WAITING_FOR_PRODUCTION
    ].includes(status)