import { gql } from '@apollo/client';

export const GET_ALL_SHADES = gql`
  query getAllItemShades {
    getAllItemShades {
      id
      label
      crown
    }
  }
`;
