import { FileType } from '../types/enums';
import { File } from '../types/interfaces';

export const get3OXZFile = (allFiles : File[]) : File[] => {
  return allFiles
      .filter((file) =>
          file?.name?.toLowerCase() === FileType.ORDER_FILE.replace("_", "").toLowerCase()
      );
}

export const getTraceabilitiesFile = (allFiles : File[]) : File[] => 
    allFiles
        .filter((file) => 
            file?.name?.toUpperCase() === FileType.BILLING_DENTIST_TRACEABILITY ||
            file?.name?.toUpperCase() === FileType.BILLING_PATIENT_TRACEABILITY
        )

export const getImagesFile = (allFiles : File[]) : File[] => 
    allFiles
        .filter((file) => {
            const fileNameSplit = file?.name?.toLocaleLowerCase().split('.');
            return file?.name?.includes('.DCM') ||
            ['jpg','jpeg','png'].includes(fileNameSplit[fileNameSplit.length - 1])
        })
