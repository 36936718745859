import React from "react";
import {
  Grid,
  Button,
} from "@mantine/core";

import {
  STYLE,
} from "../types/constants";

const SelectorButton = (props: {
  data: { value: string, label: string }[],
  selected: string,
  onSubmit: () => void,
}) => {
  return (
    <Grid>
      {(props.data ?? []).map((item: { value: string, label: string }) => {
        return (
          <Grid.Col span={6}>
            <Button
              style={{
                backgroundColor: props.selected === item.value ? STYLE.primary : "lightgrey",
                border: props.selected === item.value ? "1px solid black" : "0px",
                width: "100%",
              }}
              key={item.value}
              onClick={() => props.onSubmit(item.value)}
            >
              {item.label}
            </Button>
          </Grid.Col>
        )
      })}
    </Grid>
  );
};

export default SelectorButton;
