import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { GET_ORDER_BY_UNIQUE_ATTRIBUTE, GET_ALL_MESSAGES_OF_ORDER, SEND_MESSAGE } from '/@/gql';
import { OrderViewPage } from '@jasper/shared';
import { UseAuth } from '/@/contexts';
import { useEffect, useState } from 'react';
import {io} from "socket.io-client";
import { getApiUrl } from '../../config';

const OrderView = () => {

  const { order_id } = useParams();
  const { user } = UseAuth();

  const { data, refetch } = useQuery(
    GET_ORDER_BY_UNIQUE_ATTRIBUTE,
    {
      variables: {
        where: {
          id:  order_id
        }
      }
    }
  );
  
  return (
    <OrderViewPage 
      order={data?.getOrderByUniqueAttribute} 
      user={user} 
      refetch={refetch} 
    />
  )
};


export default OrderView;
