import {
  useState,
} from "react";
import {
  Text,
  Select,
  Space,
  Switch,
} from '@mantine/core';
import { Controller } from 'react-hook-form';
import { MantineProvider } from '@mantine/core';

const YesNoSelectorRef = ({ name, label, setValue, value }) => {
  const [isChecked, setIsChecked] = useState<boolean>(value); 

  return (
    <MantineProvider>
      <Switch
        style={{ paddingTop: "1.8rem" }}
        label={label}
        checked={isChecked}
        onChange={() => {
          setValue(!isChecked);
          setIsChecked(!isChecked);
        }}
      />
      <Space h="md"/>
    </MantineProvider>
  )
}

export default YesNoSelectorRef;
