import { createContext } from 'react';
import axios from 'axios';
import { getApiUrl } from '/@/config';
import Cookies from 'js-cookie';
import { notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";

export const UserContext = createContext();

export const UseAuth = () => {

  const getDomain = () => {
    switch (import.meta.env.MODE) {
      case "staging":
        return ".jasperdental.fr";
      case "production":
        return ".jasperdental.fr";
      default:
        return "localhost";
    }
  }

  const login = async (data: { email: string, password: string }): Promise<boolean> => {
    try {
      const req = await axios.post(`${getApiUrl()}/auth/login`, data);
      Sentry.setUser({
        id: req.data.id,
        email: data.email,
      })
      Cookies.set("email", data.email, { domain: getDomain() });
      Cookies.set("userGroupId", req.data.userGroupId, { domain: getDomain() });
      Cookies.set("id", req.data.id, { domain: getDomain() });
      Cookies.set("role", req.data.role, { domain: getDomain() });
      Cookies.set("type", req.data.type, { domain: getDomain() });
      Cookies.set("Authentication", req.data.accessToken, { domain: getDomain() });
      if (req.data.refreshToken.split("Refresh=").length > 1){
        Cookies.set("Refresh", req.data.refreshToken.split("Refresh=")[1].split(';')[0], { domain: getDomain() });
      }
      return (req.data);
    } catch (e) {
      notifications.show({
        title: "Wrong email or password",
        color: "red",
        message: "",
      });
      console.error(e);
      return (false);
    }
  };

  const getUser = () => {
    return {
      id: Cookies.get('id'),
      email: Cookies.get('email'),
      role: Cookies.get('role'),
      type: Cookies.get('type'),
      userGroupId: Cookies.get('userGroupId'),
    };
  };

  const clearCookies = () => {
    Cookies.remove("id");
    Cookies.remove("email");
    Cookies.remove("role");
    Cookies.remove("type");
    Cookies.remove("Refresh");
    Cookies.remove("Authentication");
    Cookies.remove('userGroupId');
  };

  return {
    user: getUser(),
    login,
    clearCookies,
  };
};
