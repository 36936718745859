import {
  useState,
} from "react";
import {
  useQuery,
} from "@apollo/client";
import {
  Button,
  Table,
  Space,
} from "@mantine/core";
import {
  useNavigate
} from "react-router-dom";
import {useDebounce} from "use-debounce";

import {
  GET_ALL_USERS,
  STYLE,
  SearchInput,
} from "@jasper/shared";

const _TAKE_ = 20;

const UserList = () => {

  const [skip, setSkip] = useState<number>(0);
  const [allUsers, setAllUsers] = useState<{id: string, firstName: string, lastName: string, email: string}[]>([]);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchInputDebounce] = useDebounce(searchInput, 500);

  const navigate = useNavigate();

  const { data } = useQuery(
    GET_ALL_USERS,
    {
      variables: {
        take: _TAKE_,
        skip: skip,
        where: searchInputDebounce
        ? {
          OR: [
            {
              firstName: {
                contains: searchInputDebounce,
                mode: "insensitive",
              },
            },
            {
              lastName: {
                contains: searchInputDebounce,
                mode: "insensitive",
              }
            }
          ]
        } : {},
        orderBy: [
          {
            lastName: "asc",
          }
        ]
      },
      onCompleted: (data) => {
        setAllUsers([
          ...allUsers,
          ...data?.users ?? []
        ]);
        if (
          (data?.users ?? []).length > 0
          && data?.users.length % _TAKE_ === 0
        ){
          setShowLoadMoreButton(true);
        } else {
          setShowLoadMoreButton(false);
        }
      },
      fetchPolicy: "network-only"
    }
  );

  return (
    <>
      <SearchInput
        onChange={(value) => {
          if (searchInput !== value){
            setAllUsers([]);
            setSkip(0);
          }
          setSearchInput(value);
        }}
        searchInput={searchInput}
        label="Search user by lastname or firstname"
        placeholder="Search user by lastname or firstname"
      />
      <div style={{ textAlign: "right" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={() => navigate("/users/new/")}
        >
          Create a new user
        </Button>
      </div>
      <Table highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Firstname</Table.Th>
            <Table.Th>Lastname</Table.Th>
            <Table.Th>Email</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {allUsers.map((user: { id: string, firstName: string, lastName: string, email: string }) => {
            return (
              <Table.Tr  
                key={user.id}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/users/${user.id}`)}
              >
                <Table.Td>{user.firstName}</Table.Td>
                <Table.Td>{user.lastName}</Table.Td>
                <Table.Td>{user.email}</Table.Td>
              </Table.Tr>
            )
          })}
        </Table.Tbody>
      </Table>
      <Space h="md"/>
      {showLoadMoreButton &&
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={() => setSkip(allUsers.length)}
            style={{ backgroundColor: STYLE.primary }}
          >
            Load more
          </Button>
        </div>
      }
    </>
  );
};

export default UserList;
