import { Title } from '@mantine/core';

export const Heading = (props: {
  value: string,
  center?: boolean
}) => {
  return (
    <Title order={3} style={ props.center && {textAlign: "center"} }>
      {props.value}
    </Title>
  )
}
