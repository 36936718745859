import { useState } from 'react';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';
import {
  useQuery,
  useMutation,
} from '@apollo/client';
import {
  Text,
  Button,
  Space,
  Title,
  Modal,
  Select,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';

import {
  GET_ORDER_BY_UNIQUE_ATTRIBUTE,
  DELETE_MANY_PRODUCTS,
  DELETE_MANY_ANATOMY_ITEMS,
  DELETE_MANY_REMOVABLE_ITEMS,
  DELETE_MANY_IMPLANT_ITEM,
} from '../../../gql/';
import CreateAnatomyItemComponent from './CreateAnatomyItemComponent';
import AnatomyItemComponent from './AnatomyItemComponent';
import RemovableItemComponent from './RemovableItemComponent';
import CreateRemovableItemComponent from './CreateRemovableItemComponent';
import CreateProductComponent from './CreateProductComponents';
import ImplantItemComponent from './ImplantItemComponent';
import CreateImplantItemComponent from './CreateImplantItemComponent';

import {
  Product,
  ItemType,
  STYLE,
  TOGGLE_FEATURE,
} from '@jasper/shared';

const AnatomyItemView = ({ product, onInsert, onDelete, onUpdate }) => {
  return (
    <>
      <li>
        <span style={{ fontWeight: 'bold' }}>Anatomy Items:&nbsp;</span>
        <span style={{ textDecoration: 'underline', cursor: "pointer"}} onClick={onInsert}>(add new)</span>
      </li>
      {(product?.anatomyItem ?? []).map((item, index) => (
        <div style={{ marginLeft: '3rem' }} key={item.id}>
          <AnatomyItemComponent item={item} index={index} onDelete={() => onDelete(item.id)} onUpdate={() => onUpdate(item)}/>
        </div>
      ))}
    </>
  )
}

const RemovableItemView = ({ product, onInsert, onDelete, onUpdate }) => {
  return (
    <>
      <li>
        <span style={{ fontWeight: 'bold' }}>Removable Items:&nbsp;</span>
        <span style={{ textDecoration: 'underline', cursor: "pointer"}} onClick={onInsert}>(add new)</span>
      </li>
      {(product?.removableItem ?? []).map((item, index) => (
        <div style={{ marginLeft: '3rem' }} key={item.id}>
          <RemovableItemComponent item={item} index={index} onDelete={() => onDelete(item.id)} onUpdate={() => onUpdate(item)}/>
        </div>
      ))}
    </>
  )
};

const ImplantItemView = ({ product, onInsert, onDelete, onUpdate }) => {
  return (
    <>
      <li>
        <span style={{ fontWeight: 'bold' }}>Implant Items:&nbsp;</span>
        <span style={{ textDecoration: 'underline', cursor: "pointer"}} onClick={onInsert}>(add new)</span>
      </li>
      {(product?.implantItem ?? []).map((item, index) => (
        <div style={{ marginLeft: '3rem' }} key={item.id}>
          <ImplantItemComponent item={item} index={index} onDelete={() => onDelete(item.id)} onUpdate={() => onUpdate(item)}/>
        </div>
      ))}
    </>
  );
};

const OrderEdit = () => {

  const { order_id } = useParams();
  const navigate = useNavigate();

  const [isModalNewAnatomyItemOpen, setIsModalNewAnatomyItemOpen] = useState<boolean>(false);
  const [isModalNewRemovableItemOpen, setIsModalNewRemovableItemOpen] = useState<boolean>(false);
  const [isModalNewImplantItemOpen, setIsModalNewImplantItemOpen] = useState<boolean>(false);
  const [isModalNewProductOpen, setIsModalNewProductOpen] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState(null);

  const [deleteManyProduct] = useMutation(DELETE_MANY_PRODUCTS);
  const [deleteManyAnatomyItems] = useMutation(DELETE_MANY_ANATOMY_ITEMS);
  const [deleteManyRemovableItems] = useMutation(DELETE_MANY_REMOVABLE_ITEMS);
  const [deleteManyImplantItems] = useMutation(DELETE_MANY_IMPLANT_ITEM);

  const { data, refetch } = useQuery(
    GET_ORDER_BY_UNIQUE_ATTRIBUTE,
    {
      variables: {
        where: {
          id:  order_id
        }
      }
    }
  );


  const deleteProduct = async(productId: string): void => {
    const checkConfirm = confirm("You are going to delete this product and all related items, this action is irreversible");
    if (!checkConfirm){
      notifications.show({
        title: "Deletion aborted",
        color: "red",
        message: "",
      }); 
      return ;
    }
    try {
      await deleteManyProduct({
        variables: {
          where: {
            id: {
              equals: productId
            }
          }
        }
      });
      notifications.show({
        title: "Product deleted",
        color: "green",
        message: "",
      });
      refetch();
    } catch (e) {
      notifications.show({
        title: "Error while deleting product",
        color: "red",
        message: "",
      }); 
    }
  };

  const deleteAnatomyItem = async(itemId: string): void => {
    const checkConfirm = confirm("You are going to delete this item, this action is irreversible");
    if (!checkConfirm){
      notifications.show({
        title: "Deletion aborted",
        color: "red",
        message: "",
      }); 
      return ;
    }
    try {
      await deleteManyAnatomyItems({
        variables: {
          where: {
            id: {
              equals: itemId
            }
          }
        }
      })
      notifications.show({
        title: "Item deleted",
        color: "green",
        message: "",
      });
      refetch();
    } catch (e) {
      notifications.show({
        title: "Error while deleting item",
        color: "red",
        message: "",
      });  
    }
  };

  const deleteRemovableItem = async (itemId: string): void => {
    const checkConfirm = confirm("You are going to delete this item, this action is irreversible");
    if (!checkConfirm){
      notifications.show({
        title: "Deletion aborted",
        color: "red",
        message: "",
      }); 
      return ;
    }
    try {
      await deleteManyRemovableItems({
        variables: {
          where: {
            id: {
              equals: itemId
            }
          }
        }
      })
      notifications.show({
        title: "Item deleted",
        color: "green",
        message: "",
      });
      refetch();
    } catch (e) {
      notifications.show({
        title: "Error while deleting item",
        color: "red",
        message: "",
      });  
    }
  };

  const deleteImplantItem = async (itemId: string): void => {
    const checkConfirm = confirm("You are going to delete this item, this action is irreversible");
    if (!checkConfirm){
      notifications.show({
        title: "Deletion aborted",
        color: "red",
        message: "",
      }); 
      return ;
    }
    try {
      await deleteManyImplantItems({
        variables: {
          where: {
            id: {
              equals: itemId
            }
          }
        }
      })
      notifications.show({
        title: "Item deleted",
        color: "green",
        message: "",
      });
      refetch();
    } catch (e) {
      notifications.show({
        title: "Error while deleting item",
        color: "red",
        message: "",
      });  
    }
  };

  return (
    <>
      <Modal
        opened={isModalNewAnatomyItemOpen}
        onClose={() => setIsModalNewAnatomyItemOpen(false)}
        title="New anatomy item"
      >
        <CreateAnatomyItemComponent
          closeModal={() => setIsModalNewAnatomyItemOpen(false)}
          productId={selectedProductId}
          item={selectedItem}
          refetch={refetch}
          redirect={(itemType) => {
            if (TOGGLE_FEATURE.NEW_WORKFLOW) {
              navigate(`/orders/${order_id}/edit/${selectedProductId}/new?itemType=${itemType}`)
            }
          }}
        />
      </Modal>
      <Modal
        opened={isModalNewRemovableItemOpen}
        onClose={() => setIsModalNewRemovableItemOpen(false)}
        title="New removable item"
      >
        <CreateRemovableItemComponent
          closeModal={() => setIsModalNewRemovableItemOpen(false)}
          productId={selectedProductId}
          item={selectedItem}
          refetch={refetch}
        />
      </Modal>
      <Modal
        opened={isModalNewImplantItemOpen}
        onClose={() => setIsModalNewImplantItemOpen(false)}
        title="New implant item"
      >
        <CreateImplantItemComponent
          closeModal={() => setIsModalNewImplantItemOpen(false)}
          productId={selectedProductId}
          item={selectedItem}
          refetch={refetch}
        />
      </Modal>
      <Modal
        opened={isModalNewProductOpen}
        onClose={() => setIsModalNewProductOpen(false)}
        title="New product"
      >
        <CreateProductComponent
          closeModal={() => setIsModalNewProductOpen(false)}
          orderId={order_id}
          product={(data?.getOrderByUniqueAttribute?.products ?? []).find((product) => product.id === selectedProductId)}
          refetch={refetch}
        />
      </Modal>
      {(data?.getOrderByUniqueAttribute.products ?? []).map((product: Product, index: number) => {
        return (
          <div
            key={product.id}
            style={{
              border: '0.02rem solid lightgrey',
              padding: '1rem',
              margin: '1rem',
            }}
          >
            <Title order={2}>Product {index + 1}</Title>
            <Text><span style={{ fontWeight: 'bold' }}>Type:</span> {product.productType}</Text>
            <Text><span style={{ fontWeight: 'bold' }}>Teeth:</span> {product.teeth.join(", ")}</Text>
            <Text style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
              setSelectedProductId(product.id);
              setIsModalNewProductOpen(true);
            }}>Update product</Text>
            <Text style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => deleteProduct(product.id)}>Delete product</Text>
            <div
              style={{ marginLeft: '3rem' }}
            >
              <AnatomyItemView
                product={product}
                onInsert={() => {
                  setSelectedItem(null);
                  setSelectedProductId(product.id);
                  setIsModalNewAnatomyItemOpen(true);
                }}
                onUpdate={(item) => {
                  if (TOGGLE_FEATURE.NEW_WORKFLOW && item.itemType === ItemType.INLAY_CORE) {
                    navigate(`/orders/${order_id}/edit/${product.id}/edit?itemType=${item.itemType}&itemId=${item.id}`, {
                      state: {
                        item: item,
                      }
                    })
                    return;
                  }
                  setSelectedItem(item);
                  setIsModalNewAnatomyItemOpen(true);
                }}
                onDelete={(itemId) => deleteAnatomyItem(itemId)}
              />
              <RemovableItemView
                product={product}
                onInsert={() => {
                  setSelectedItem(null);
                  setSelectedProductId(product.id);
                  setIsModalNewRemovableItemOpen(true);
                }}
                onUpdate={(item) => {
                  setSelectedItem(item);
                  setIsModalNewRemovableItemOpen(true);
                }}
                onDelete={(itemId) => deleteRemovableItem(itemId)}
              />
              <ImplantItemView
                product={product}
                onInsert={() => {
                  setSelectedItem(null);
                  setSelectedProductId(product.id);
                  setIsModalNewImplantItemOpen(true);
                }}
                onUpdate={(itemId) => {
                  setSelectedItem(itemId);
                  setIsModalNewImplantItemOpen(true);
                }}
                onDelete={(itemId) => deleteImplantItem(itemId)}
              />
            </div>
          </div>
        )
      })}
      <Space h="md"/>
      <div style={{ textAlign: 'center' }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={() => {
            setSelectedProductId("");
            setIsModalNewProductOpen(true)
          }}
        >
          Add a new product
        </Button>
      </div>
    </>
  );
};

export default OrderEdit;
