import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Text } from '@mantine/core';

import { UseAuth } from '/@/contexts';
import Logo from "/@/assets/logo.svg";
import {
  STYLE,
  OrderStatus,
  UserType,
  UserRole,
  isUserProvider,
  isUserStaff,
  isUserAdmin,
} from '@jasper/shared';

import packageJson from "../../package.json";

const NavLink = (props: {
  title: string,
  onClick: () => any,
  active?: boolean,
}) => {
  return (
    <div
      style={{
        cursor: "pointer",
        color: props.active ? STYLE.primary : "#050027",
        fontWeight: 650,
        textAlign: "left",
        paddingBottom: "0.5rem"
      }}
      onClick={props.onClick}
    >
      {props.title}
    </div>
  );
};

const Navbar = () => {
  const { clearCookies, user } = UseAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [url, setUrl] = useState<string>("");
  const [searchParams] = useSearchParams();

  const logout = () => {
    clearCookies();
    navigate('/');
  }

  const nextUrl = (urlToNavigate: string) => {
    navigate(urlToNavigate);
    setUrl(urlToNavigate);
  }

  useEffect(() => {}, [url])

  return (
    <div
      style={{
        textAlign: "center"
      }}
    >
      <div
        style={{
          paddingBottom: "2rem"
        }}
      >
        <img src={Logo}/>
        <div style={{color: "lightgrey"}}>
        <Text
          size="xs"
          style={{textAlign: "center"}}
        >
          v{packageJson.version}
        </Text>
        </div>
      </div>
      {(isUserAdmin(user)) &&
        <>
          <NavLink
            title="INBOX/NEW CASES"
            onClick={() => nextUrl(`/home?status=${OrderStatus.CONFIRMED}&page=0`)}
            active={searchParams.get('status') === OrderStatus.CONFIRMED}
          />
          <hr/>
          <NavLink
            title={OrderStatus.WAITING_FOR_PRODUCTION}
            onClick={() => nextUrl(`/home?status=${OrderStatus.WAITING_FOR_PRODUCTION}&page=0`)}
            active={searchParams.get('status') === OrderStatus.WAITING_FOR_PRODUCTION}
          />
          <NavLink
            title={OrderStatus.PRODUCING}
            onClick={() => nextUrl(`/home?status=${OrderStatus.PRODUCING}&page=0`)}
            active={searchParams.get('status') === OrderStatus.PRODUCING}
          />
          <NavLink
            title={OrderStatus.PRODUCING_IN_TRANSIT}
            onClick={() => nextUrl(`/home?status=${OrderStatus.PRODUCING_IN_TRANSIT}&page=0`)}
            active={searchParams.get('status') === OrderStatus.PRODUCING_IN_TRANSIT}
          />
          <NavLink
            title={OrderStatus.SHIPPED}
            onClick={() => nextUrl(`/home?status=${OrderStatus.SHIPPED}&page=0`)}
            active={searchParams.get('status') === OrderStatus.SHIPPED}
          />
          <NavLink
            title={OrderStatus.DELIVERED}
            onClick={() => nextUrl(`/home?status=${OrderStatus.DELIVERED}&page=0`)}
            active={searchParams.get('status') === OrderStatus.DELIVERED}
          />
        </>
      }
      {isUserProvider(user) &&
        <>
          <NavLink
            title={OrderStatus.WAITING_FOR_PRODUCTION}
            onClick={() => nextUrl(`/home?status=${OrderStatus.WAITING_FOR_PRODUCTION}&page=0`)}
            active={searchParams.get('status') === OrderStatus.WAITING_FOR_PRODUCTION}
          />
          <NavLink
            title={OrderStatus.PRODUCING}
            onClick={() => nextUrl(`/home?status=${OrderStatus.PRODUCING}&page=0`)}
            active={searchParams.get('status') === OrderStatus.PRODUCING}
          />
          <NavLink
            title="SHIPPED TO FRANCE"
            onClick={() => nextUrl(`/home?status=${OrderStatus.PRODUCING_IN_TRANSIT}&page=0`)}
            active={searchParams.get('status') === OrderStatus.PRODUCING_IN_TRANSIT}
          />
          <NavLink
            title="TO SHIP"
            onClick={() => nextUrl(`/home?status=TO_SHIP&page=0`)}
            active={searchParams.get('status') === "TO_SHIP"}
          />
          <NavLink
            title="DELAYED"
            onClick={() => nextUrl(`/home?status=DELAYED&page=0`)}
            active={searchParams.get('status') === "DELAYED"}
          />
        </>
      }
      {(isUserAdmin(user) || isUserProvider(user)) &&
        <>
          <hr/>
          <NavLink
            title={'UNREAD MESSAGES'}
            onClick={() => nextUrl(`/chats`)}
            active={window.location.pathname.search("/chats") >= 0}
          />
        </>
      }
      <div
        style={{
          position: "absolute",
          bottom: "1rem"
        }}
      >
        {isUserStaff(user) &&
          <>
            {(isUserAdmin(user)) &&
              <>
                <NavLink
                  title="ALL"
                  onClick={() => nextUrl('/home?status=ALL&page=0')}
                  active={searchParams.get('status') === "ALL"}
                />
                <NavLink
                  title={OrderStatus.DRAFT}
                  onClick={() => nextUrl(`/home?status=${OrderStatus.DRAFT}&page=0`)}
                  active={searchParams.get('status') === OrderStatus.DRAFT}
                />
                <hr style={{ width: "11.5vw" }}/>
              </>
            }
            <NavLink
              title="GROUPS"
              onClick={() => nextUrl(`/groups`)}
              active={window.location.pathname.search("/groups") >= 0}
            />
            <NavLink
              title="USERS"
              onClick={() => nextUrl(`/users`)}
              active={window.location.pathname.search("/users") >= 0}
            />
            <hr style={{ width: "11.5vw" }}/>
          </>
        }
        <NavLink
          title="Log out"
          onClick={logout}
        />
      </div>
    </div>
  );
};

export default Navbar;
