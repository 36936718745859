import {
  Space,
  Text,
  Button,
} from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { notifications } from "@mantine/notifications";

import TextInputRef from '../TextInputRef';
import SelectInputRef from '../SelectInputRef';
import {
  UserType,
} from '../../types/enums';
import {
  GET_ALL_PROVIDERS,
} from '../../gql/users';
import {
  GET_ALL_ITEM_MATERIALS,
}  from '../../gql/material';
import {
  CREATE_MATERIAL_TRACEABILITY,
} from '../../gql/materialTraceability';
import {
  STYLE
} from '../../types/constants';

const CreateMaterialTraceabilityComponent = ({ close, refetchMaterialTraceability, user }) => {

  const { data: providerList } = useQuery(GET_ALL_PROVIDERS);
  const { data: itemMaterialList } = useQuery(GET_ALL_ITEM_MATERIALS);

  const [createMaterialTraceability] = useMutation(CREATE_MATERIAL_TRACEABILITY);

  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await createMaterialTraceability({
        variables: {
          args: {
            itemMaterial: {
              connect: {
                id: data.itemMaterialId,
              }
            },
            firstMaterialApprovalReference: data.firstMaterialApprovalReference,
            firstMaterialBrand: data.firstMaterialBrand,
            firstMaterialCountry: data.firstMaterialCountry,
            firstMaterialReference: data.firstMaterialReference,
            secondMaterialApprovalReference: data.secondMaterialApprovalReference,
            secondMaterialBrand: data.secondMaterialBrand,
            secondMaterialCountry: data.secondMaterialCountry,
            secondMaterialReference: data.secondMaterialReference,
            provider: {
              connect: {
                id: data?.providerId ?? user.id,
              }
            }
          }
        }
      });
      notifications.show({
        title: "Succefully created new material traceability",
        color: "green",
        message: "",
      });
      refetchMaterialTraceability();
      close();
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Cannot create materialTraceability",
        color: "red",
        message: "",
      });
    }
  };

  return (
    <>
      {user.type !== UserType.SUPPLIER &&
        <>
          <SelectInputRef
            name="providerId"
            label="Provider *"
            data={(providerList?.getAllProviders ?? []).map((provider) => ({ value: provider.id, label: provider.name }))}
            control={control}
            errors={errors}
          />
          <Space h="md" />
        </>
      }
      <SelectInputRef
        name="itemMaterialId"
        label="Material *"
        data={(itemMaterialList?.getAllItemMaterials ?? []).map((material) => ({ value: material.id, label: material.label }))}
        control={control}
        errors={errors}
      />
      <Space h="md" />
      <TextInputRef
        name="firstMaterialBrand"
        label="First material brand *"
        control={control}
        errors={errors}
      />
      <Space h="md" />
      <TextInputRef
        name="firstMaterialReference"
        label="First material reference *"
        control={control}
        errors={errors}
      />
      <Space h="md" />
      <TextInputRef
        name="firstMaterialCountry"
        label="First material country *"
        control={control}
        errors={errors}
      />
      <Space h="md" />
      <TextInputRef
        name="firstMaterialApprovalReference"
        label="First material approval reference *"
        control={control}
        errors={errors}
      />
      <Space h="md" />
      <TextInputRef
        name="secondMaterialBrand"
        label="Second material brand"
        control={control}
        errors={errors}
        required={false}
      />
      <Space h="md" />
      <TextInputRef
        name="secondMaterialReference"
        label="Second material reference"
        control={control}
        errors={errors}
        required={false}
      />
      <Space h="md" />
      <TextInputRef
        name="secondMaterialCountry"
        label="Second material country"
        control={control}
        errors={errors}
        required={false}
      />
      <Space h="md" />
      <TextInputRef
        name="secondMaterialApprovalReference"
        label="Second material approval reference"
        control={control}
        errors={errors}
        required={false}
      />
      <Space h="md" />
      <Text
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={close}
      >
        I want to select an actual material traceability
      </Text>
      <Space h="md" />
      <div style={{ textAlign: 'center' }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={handleSubmit(onSubmit)}
        >
          Create new material traceability
        </Button>
      </div>
    </>
  )
};

export default CreateMaterialTraceabilityComponent;
