import { useState } from 'react';
import {
  Text,
  Space,
  Textarea,
  Button,
} from '@mantine/core';
import { useMutation } from '@apollo/client';
import { notifications } from "@mantine/notifications";

import {
  TRANSLATE_COMMENT,
  UPDATE_ORDER,
} from '../../gql/orders';
import { STYLE } from '../../types/constants';
import {
  OrderStatus,
} from '../../types/enums';

const TranslateCommentModal = ({ order, closeModal, refetch, comment }) => {

  const [translatedComment, setTranslatedComment] = useState<string>("");
  const [translateCommentMutation] = useMutation(TRANSLATE_COMMENT);
  const [updateOrder] = useMutation(UPDATE_ORDER);

  const submitTranslateComment = async () => {
    if (!translatedComment){
      notifications.show({
        title: "You cannot set an empty comment",
        color: "red",
        message: "",
      }); 
      return;
    }
    try {
      await translateCommentMutation({
        variables: {
          where: {
            id: comment?.id,
          },
          data: {
            translatedComment: {
              set: translatedComment,
            },
          }
        }
      });
      notifications.show({
        title: "Comment is correctly translated",
        color: "green",
        message: "",
      });
      refetch();
      closeModal();
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to update the comment",
        color: "red",
        message: "",
      });
    }
  };

  return (
    <>
      <Text style={{ fontWeight: 'bold' }}>Comment:</Text>
      <Text style={{ fontStyle: 'italic' }}>{comment?.comment}</Text>
      <Space h="md"/>
      <Textarea
        label="Translate comment:"
        rows={4}
        value={translatedComment}
        onChange={(e) => setTranslatedComment(e.target.value)}
      />
      <Space h="md"/>
      <Button
        style={{ backgroundColor: STYLE.primary }}
        onClick={submitTranslateComment}
      >
        Submit translation        
      </Button>
    </>
  );
};

export default TranslateCommentModal;
