import { Button as MantineButton } from '@mantine/core';

import { STYLE } from '@jasper/shared';

export const Button = (props: {
  value: string,
  onClick: () => any,
  testid: string,
}) => {
  return (
    <div style={{ marginTop: "1rem", textAlign: 'center' }}>
      <MantineButton onClick={props.onClick} data-testid={props.testid} color={STYLE.primary}>{props.value}</MantineButton>
    </div>
  );
};
