import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  Button,
  Space,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { MantineProvider } from '@mantine/core';

import {
  ItemType,
  SelectInputRef,
  TextInputRef,
  STYLE,
} from '@jasper/shared';

import {
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
} from '../../../gql/';

const CreateProductComponent = ({ closeModal, orderId, refetch, product }) => {
  const [createProduct] = useMutation(CREATE_PRODUCT);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: {
      productType: product?.productType,
      teeth: product?.teeth?.join(',')
    }
  });

  const submit = async(data) => {
    try {
      if (product?.id){
        await updateProduct({
          variables: {
            data: {
              productType: {
                set: data.productType,
              },
              teeth: data.teeth.split(',').map((t) => parseInt(t)),
            },
            where: {
              id: product.id
            }
          }
        });
      } else {
        await createProduct({
          variables:{
            args: {
              teeth: data.teeth.split(',').map((t) => parseInt(t)),
              productType: data.productType,
              order: {
                connect: {
                  id: orderId
                }
              },
              itemPreferences: undefined
            }
          }
        });
        notifications.show({
          title: "Product created",
          color: "green",
          message: "",
        });
      }
      refetch();
    } catch (e) {
      notifications.show({
        title: "Error while trying to create or update product",
        color: "red",
        message: "",
      });
    }
    closeModal();
  };

  return (
    <MantineProvider>
      <SelectInputRef
        name="productType"
        data={Object.keys(ItemType)}
        label="Product type"
        errors={errors}
        control={control}
      />
      <Space h="md"/>
      <TextInputRef
        name="teeth"
        label="Teeths (e.g 4,5)"
        errors={errors}
        control={control}
      />  
      <Space h="md"/>
      <div style={{ textAlign: 'center' }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={handleSubmit(submit)}
        >
          Submit
        </Button>
      </div>
    </MantineProvider>
  );
};

export default CreateProductComponent;
