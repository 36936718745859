import {
  useState,
} from "react";
import {
  useQuery,
  useMutation,
} from "@apollo/client";
import {
  useParams,
  useNavigate,
} from "react-router-dom";
import {
  Table,
  Grid,
  Title,
  Text,
  Button,
  Modal,
} from "@mantine/core";
import { notifications } from '@mantine/notifications';

import {
  STYLE,
  UPDATE_USER,
} from "@jasper/shared";

import {
  GET_GROUP_BY_ID,
} from "../../gql/groups";
import AddNewMemberToGroupModal from "./modals/AddNewMemberToGroupModal";

const GroupView = () => {

  const [isNewMemberModalOpen, setIsNewMemberModalOpen] = useState<boolean>(false);

  const { group_id } = useParams();
  const navigate = useNavigate();

  const [updateUser] = useMutation(UPDATE_USER);

  const { data, refetch } = useQuery(
    GET_GROUP_BY_ID,
    {
      variables: {
        where: {
          id: group_id,
        }
      },
      fetchPolicy: "network-only",
    }
  );

  const deleteMemberFromGroup = async (user_id: string) => {
    try {
      await updateUser({
        variables: {
          where: {
            id: user_id,
          },
          data: {
            userGroup: {
              disconnect: {
                id: {
                  equals: group_id,
                }
              }
            }
          }
        }
      });
      refetch();
      notifications.show({
        title: "User correctly removed from group",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to remove user from group",
        color: "red",
        message: "",
      });
    }
  };

  return (
    <>
      <Modal
        opened={isNewMemberModalOpen}
        onClose={() => setIsNewMemberModalOpen(false)}
        title="Add a new member to this group"
      >
        <AddNewMemberToGroupModal
          group_id={group_id}
          closeModal={() => setIsNewMemberModalOpen(false)}
          refetch={refetch}
        />
      </Modal>
      <Grid style={{ textAlign: "center", paddingBottom: "2rem" }}>
        <Grid.Col span={4}>
          <Title fw={200}>Group Name</Title>
          <Text>{ data?.getUserGroupsWhereUnique?.name }</Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Title fw={200}>Group type</Title>
          <Text>{ data?.getUserGroupsWhereUnique?.groupType }</Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Title fw={200}>Clinic Number</Title>
          <Text>{ data?.getUserGroupsWhereUnique?.clinicNumber }</Text>
        </Grid.Col>
      </Grid>
      <Title fw={200} style={{ paddingBottom: "1rem" }}>Members:</Title>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Lastname</Table.Th>
            <Table.Th>Firstname</Table.Th>
            <Table.Th>Email</Table.Th>
            <Table.Th>Is master account</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {(data?.getUserGroupsWhereUnique?.groupMembers ?? []).map((member) => {
            return (
              <Table.Tr key={member.id}>
                <Table.Td>{member.lastName}</Table.Td>
                <Table.Td>{member.firstName}</Table.Td>
                <Table.Td style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/users/${member.id}`)}>{member.email}</Table.Td>
                <Table.Td>{member.isManager ? "YES" : "NO"}</Table.Td>
                <Table.Td
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => deleteMemberFromGroup(member.id)}
                >
                  Delete
                </Table.Td>
              </Table.Tr>
            )
          })}
        </Table.Tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "space-around", marginTop: "3rem" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={() => setIsNewMemberModalOpen(true)}
        >
          Add an existing user as a member
        </Button>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={() => navigate(`/users/new/`, {
            state: {
              user: {
                userGroup: {
                  id: group_id,
                  clinicNumber: data?.getUserGroupsWhereUnique?.clinicNumber
                }
              }
            }
          })}
        >
          Create a new user and add it as a member
        </Button>
      </div>
    </>
  );
};

export default GroupView;
