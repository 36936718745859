import {
  OrderStatus,
  CommentType,
} from '../types/enums';
import { Product } from '../types/interfaces';

export const getOrderStatusStep = (status: OrderStatus): number => {
  switch (status) {
    case OrderStatus.CONFIRMED:
      return 1;
    case OrderStatus.PRODUCING:
    case OrderStatus.WAITING_FOR_PRODUCTION:
      return 2;
    case OrderStatus.PRODUCING_IN_TRANSIT:
      return 2;
    case OrderStatus.SHIPPED:
      return 3;
    case OrderStatus.DELIVERED:
      return 4;
  }
  return 0;
};

export const getOrderFirstProduct = (newOrder: any): Product => {
  return newOrder.products[0] ?? null;
};

export const getAllOrderComments = (order: Order): number => {
  return (order?.orderComment ?? []).filter((comment) => comment.type === CommentType.ORDER);
};

export const getAllPartialDentureComments = (order: Order): number => {
  return (order?.orderComment ?? []).filter((comment) => comment.type === CommentType.PARTIAL_DENTURE);
};

export const getAllRedoComments = (order: Order): number => {
  return (order?.orderComment ?? []).filter((comment) => comment.type === CommentType.REDO);
};

export const displayOrderComments = (order: Order): string => {
  const orderComment = getAllOrderComments(order);
  return (orderComment.map(comment => comment.comment).join(". "));
};

export const displayPartialDentureComments = (order: Order): string => {
  const partialDentureComment = getAllPartialDentureComments(order);
  return (partialDentureComment.map(comment => comment.comment).join(". "));
};

export const convertDateToReadableFormat = (timestamp: number) => {
  if (!timestamp){
    return "Unplanned yet"
  }
  const dt = new Date(timestamp);
  return `${dt.toDateString()}`
};

export const getLastShippingDateFromDeliveryDate = (date: Date) => {
  if (!date){
    return "Unplanned yet"
  }
  const dt = new Date(date);
  const testPrevisionnalDt = new Date(date);
  testPrevisionnalDt.setDate(testPrevisionnalDt.getDate() - 4)
  dt.setDate(
    testPrevisionnalDt.getDay() === 0 ? 
      dt.getDate() - 5 :
      dt.getDate() - 4
  );
  return `${dt.toDateString()}`
};