import { TextInput } from '@mantine/core';
import { PasswordInput } from '@mantine/core';

export const Input = (props: {
  label: string,
  placeholder: string,
  name: string,
  register: any,
  required?: boolean,
  errors?: any,
}) => {
  const required = props.required ? true : false;
  return (
    <TextInput
      label={props.label}
      placeholder={props.placeholder}
      {...props.register(props.name, { required: required }) }
      error={props.errors?.[props.name]?.type === 'required' && "This field is mandatory"}
      data-testid={`input-${props.name}`}
    />
  );
};

export const SecureInput = (props: {
  label: string,
  placeholder: string,
  name: string,
  register: any,
  required?: boolean,
  errors?: any,
}) => {
  const required = props.required ? true : false;
  return (
    <PasswordInput
      label={props.label}
      placeholder={props.placeholder}
      {...props.register(props.name, { required: required }) }
      error={props.errors?.[props.name]?.type === 'required' && "This field is mandatory"}
      data-testid={`secure-input-${props.name}`}
    />
  )
};
