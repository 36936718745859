import {
  Title,
  Box,
} from '@mantine/core';

import {
  getProductTeeth,
  getAllTeeths,
} from '../utils/product.utils';
import { getOrderFirstProduct } from '../utils/order.utils';

import BoxOrderView from './BoxOrderView';
import DentalArchesSvg from './DentalArchesSvg';
import { STYLE } from '../types/constants';

const OrderViewSelectedTeeth = ({ order, selectedProduct }) => {
  return (
    <BoxOrderView span={5} minHeight={54}>
      <Title
        order={4}
        style={{
          color: STYLE.primary
        }}
      >
        Selected product's teeth
      </Title>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {getProductTeeth(getOrderFirstProduct(order)) && (
          <DentalArchesSvg
            teeth={
              selectedProduct?.productType === "PARTIAL_DENTURE" &&
              selectedProduct?.removableItem &&
              selectedProduct?.removableItem?.length > 0
                ? selectedProduct?.teeth
                : selectedProduct?.removableItem &&
                    selectedProduct?.removableItem?.length > 0 &&
                    selectedProduct?.removableItem[0]?.isBothJaws
                  ? getAllTeeths()
                  : selectedProduct?.teeth
            }
            width={275}
            height={366}
          />
        )}
      </Box>
    </BoxOrderView>
  );
};

export default OrderViewSelectedTeeth;
