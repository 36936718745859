import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Space,
  Button,
} from "@mantine/core";
import { MantineProvider } from '@mantine/core';

import OrderViewPatientComponent from '../components/OrderViewPatientComponent';
import OrderViewStepperComponent from '../components/OrderViewStepperComponent';
import OrderViewSelectedTeeth from '../components/OrderViewSelectedTeeth';
import OrderViewOrderDetail from '../components/OrderViewOrderDetail';
import HeaderOrderView from '../components/HeaderOrderView'
import {Chat} from '../../stories/Chat/Chat'

const OrderViewPage = ({ order, user, refetch }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    if ((order?.products ?? []).length > 0) {
      setSelectedProduct(order?.products[0]);
    }
  }, [order?.products]);

  if (!order){
    return null;
  }

  return (
    <MantineProvider>
      <Box
        p="lg"
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          height: "100%",
        })}
      >
        <HeaderOrderView order={order} user={user} refetch={refetch}/>
        <Grid
          gutter="xl"
          sx={{flexGrow: 1, minHeight: "fit-content", maxHeight: "30%"}}
        >
          <OrderViewPatientComponent order={order}/>
          <OrderViewStepperComponent order={order} user={user}/>
        </Grid>
        <Space h="xl" />
        <Grid
          gutter="xl"
          sx={{flexGrow: 1}}
        >
          <OrderViewSelectedTeeth order={order} selectedProduct={selectedProduct} />
          <OrderViewOrderDetail order={order} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} user={user} refetch={refetch} />
        </Grid>
      </Box>
    </MantineProvider>
  );
};

export default OrderViewPage;
