import {
  Box,
  Grid,
} from "@mantine/core";

import { STYLE } from '../types/constants';

const BoxOrderView = ({ children, span, minHeight }) => {
  return (
    <Grid.Col
      span={span}
      style={{
        height: "auto"
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          outline: `1px solid ${STYLE.border}`,
          padding: 16,
          height: "100%",
          minHeight: `${minHeight}vh`,
          minWidth: "100%",
        }}
      >
        {children}
      </Box>
    </Grid.Col>
  );
};

export default BoxOrderView;
