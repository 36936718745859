import { gql } from "@apollo/client";

export const GET_ALL_MATERIAL_TRACEABILITY = gql`
  query GetAllMaterialTraceability {
    getAllMaterialTraceability {
      id
      firstMaterialReference
      firstMaterialBrand
      firstMaterialApprovalReference
      firstMaterialCountry
      itemMaterialId
    }
  }
`;

export const CREATE_MATERIAL_TRACEABILITY = gql`
  mutation CreateMaterialTraceability($args: MaterialTraceabilityCreateInput!) {
    createMaterialTraceability(args: $args) {
      id
    }
  }
`
