import {
  useState,
} from "react";
import {
  TextInput,
  Space,
} from "@mantine/core";

const SearchInput = ({ onChange, label, placeholder, searchInput }) => {
  return (
    <>
      <TextInput
        sx={{flexGrow: 1}}
        placeholder={placeholder}
        label={label}
        value={searchInput}
        onChange={(event) => onChange(event.currentTarget.value)}
      />
      <Space h="md"/>
    </>
  );
};

export default SearchInput;
