import React from "react";
import {
  Text,
  Space,
} from "@mantine/core";
import {Controller} from "react-hook-form";

import SelectorButton from "./SelectorButton";

const SelectorButtonRef = (props: {
  label: string,
  name: string,
  data: any,
  onSubmit: () => void,
  control: any,
  errors: any,
  watch: any,
  required?: boolean,
}) => {
  return (
    <>
      <Text
        fw={490}
        size="sm"
      >
        {props.label}
      </Text>
      <Controller
        name={name}
        rules={{required: props.required ?? true}}
        control={props.control}
        render={({field}) => (
          <SelectorButton
            data={props.data}
            selected={props.watch}
            onSubmit={(selectedValue) => props.onSubmit(selectedValue)}
          />
        )}
      />
      {props.errors?.[props.name]?.type === "required" && (
        <Text
          size="sm"
          color="red"
        >
          This field is required
        </Text>
      )}
      <Space h="md"/>
    </>
  );
};

export default SelectorButtonRef;
