import {
  useMutation,
} from "@apollo/client";
import {
  useForm,
} from "react-hook-form";
import {
  Button,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  useNavigate,
} from "react-router-dom";

import {
  CREATE_GROUP,
} from "../../gql/groups";

import {
  TextInputRef,
  SelectInputRef,
  STYLE,
  GroupType,
} from "@jasper/shared";

const GroupNew = () => {

  const [createGroup] = useMutation(CREATE_GROUP);
  const navigate = useNavigate();

  const {
    errors,
    control,
    handleSubmit,
  } = useForm({
    defaultValues: "",
  });

  const onSubmit = async (data) => {
    try {
      const req = await createGroup({
        variables: {
          args: {
            name: data.name,
            groupType: data.type,
          }
        }
      });
      navigate(`/groups/${req?.data?.createUserGroup?.id}`);
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to create a new group, this name may already exist",
        color: "red",
        message: "",
      }); 
    }
  };

  return (
    <>
      <TextInputRef
        label="Group name"
        name="name"
        errors={errors}
        control={control}
      />
      <SelectInputRef
        label="Group type"
        name="type"
        data={Object.keys(GroupType)}
        errors={errors}
        control={control}
      />
      <div
        style={{ textAlign: "center" }}
      >
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
    </>
  )
};

export default GroupNew;
