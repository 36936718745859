export enum ItemType {
  IMPLANT = "IMPLANT",
  CROWN = "CROWN",
  SPLINT = "SPLINT",
  BLEACHING_TRAYS = "BLEACHING_TRAYS",
  LAY = "LAY",
  INLAY = "INLAY",
  ONLAY = "ONLAY",
  OVERLAY = "OVERLAY",
  BRIDGE = "BRIDGE",
  INLAY_CORE = "INLAY_CORE",
  POST_AND_CORE_RETAINED_CROWN = "POST_AND_CORE_RETAINED_CROWN",
  VENEER = "VENEER",
  IMPLANT_CROWN = "IMPLANT_CROWN",
  ABUTMENT = "ABUTMENT",
  CUSTOM_ABUTMENT = "CUSTOM_ABUTMENT",
  NIGHT_GUARD = "NIGHT_GUARD",
  RETAINER = "RETAINER",
  PARTIAL_DENTURE = "PARTIAL_DENTURE",
  FULL_DENTURE = "FULL_DENTURE",
  FULL_DENTURE_WAX_BITE = "FULL_DENTURE_WAX_BITE",
  IMMEDIATE_DENTURE = "IMMEDIATE_DENTURE",
  MODEL = "MODEL",
  RICHMOND_CROWN = "RICHMOND_CROWN",
  SPLINTED_CROWN = "SPLINTED_CROWN",
  SPLINTED_CROWN_IMPLANT = "SPLINTED_CROWN_IMPLANT",
  BRIDGE_IMPLANT = "BRIDGE_IMPLANT",
  BRIDGE_PONTIC = "BRIDGE_PONTIC",
}

export enum FileType {
  ORDER_FILE = "OrderFile",
  BILLING_DENTIST_TRACEABILITY = "Billing_Dentist_Traceability",
  BILLING_PATIENT_TRACEABILITY = "Billing_Patient_Traceability",
}
