import Login from '/@/pages/login.tsx';
import { UseAuth, UserContext } from '/@/contexts';
import { RouterProvider, Navigate } from 'react-router-dom';
import { router } from '/@/router';
import {
  AppShell,
} from "@mantine/core";

const App = () => {
  const { user } = UseAuth();

  return (
    <UserContext.Provider value={{ user }}>
      <RouterProvider router={router}/>
    </UserContext.Provider>
  )
};

export default App;
