export const getToShipDatetime = () => {
  const beginWindow = new Date();
  const endWindow = new Date();
  endWindow.setDate(endWindow.getDate() + 1);

  endWindow.setHours(23);
  endWindow.setMinutes(59);

  return ({
    beginWindow: beginWindow.getTime(),
    endWindow: endWindow.getTime(),
  })
};

export const getTodayTimestamp = () => {
  const dt = new Date();
  dt.setHours(23);
  dt.setMinutes(59);

  return (dt.getTime());
}
