const AnatomyItemComponent = ({ item, index, onDelete, onUpdate }) => {
  return (
    <>
      <li>
        <span style={{ fontWeight: 'bold' }}>
          Item {index + 1}
        </span>
        &nbsp;
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => onDelete()}>
          (delete item)
        </span>
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => onUpdate()}>
          (update item)
        </span>
      </li>
      <div style={{ marginLeft: '3rem' }}>
        <li>
          <span style={{ fontWeight: 'bold' }}>
            ItemType:
          </span>
          &nbsp;{item.itemType}
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>
            Teeth:
          </span>
          &nbsp;{item?.teeth?.join(", ")}
        </li>
        {item?.itemMaterial?.label &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              ItemMaterial:
            </span>
            &nbsp;{item?.itemMaterial?.label}
          </li>
        }
        {item?.itemShade?.label &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              ItemShade:
            </span>
            &nbsp;{item?.itemShade?.label}
          </li>
        }
        {item?.inlayCoreMaterial?.label &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              Inlay Core Material:
            </span>
            &nbsp;{item?.inlayCoreMaterial?.label}
          </li>
        }
        {item?.inlayCoreScanPost?.label &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              InlayCore ScanPost:
            </span>
            &nbsp;{item?.itemShade?.label}
          </li>
        }
        <li>
          <span style={{ fontWeight: 'bold' }}>
            InlayCore has clavette:
          </span>
          &nbsp;{item?.inlayCoreHasClavette ? "yes" : "no"}
        </li>
      </div>
    </>
  );
};

export default AnatomyItemComponent;
