import { useState, useContext } from 'react'
import { Box } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Input,
  SecureInput,
  Heading,
  Button,
} from '/@/composants/';
import { UseAuth } from '/@/contexts';

import {
  OrderStatus,
  UserType,
  isUserStaffNotAdmin,
} from "@jasper/shared";

const Login = () => {

  const [searchParams] = useSearchParams();
  const { login } = UseAuth();
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm<{email: string, password: string}>({
    defaultValues: {
      email: "",
      password: "",
    }
  })

  const submit = async (value: { email: string, password: string }) => {
    const usr = await login(value);
    if (usr){
      if (isUserStaffNotAdmin(usr)){
        navigate("/groups");
        return;
      }
      const next = searchParams.get('next') || `/home?status=${OrderStatus.WAITING_FOR_PRODUCTION}&page=0`
      navigate(next);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh"
      }}
    >
      <Box>
        <Heading value="Welcome to Jasper Lab portal" center />
        <Input
          label="Email"
          placeholder="Your email address"
          name="email"
          register={register}
          errors={errors}
          required
        />
        <SecureInput
          label="Password"
          placeholder="Your password"
          name="password"
          register={register}
          errors={errors}
          required
        />
        <Button value="Submit" onClick={handleSubmit(submit)} testid="submit-button-login"/>
      </Box>
    </div>
  )
}

export default Login;
